/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionType } from './subscriptionType';


export interface UserSubscriptionsInner { 
    /**
     * 
     */
    id: number;
    /**
     * 
     */
    trainingDescr?: string;
    /**
     * 
     */
    trainingStudypoints?: number;
    /**
     * 
     */
    organisationDescr?: string;
    role: SubscriptionType;
    /**
     * 
     */
    status: UserSubscriptionsInner.StatusEnum;
}
export namespace UserSubscriptionsInner {
    export type StatusEnum = 'active' | 'archived' | 'expired' | 'trial';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Archived: 'archived' as StatusEnum,
        Expired: 'expired' as StatusEnum,
        Trial: 'trial' as StatusEnum
    };
}


